import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import TransactionsWidget from './transactions/transactions_widget';

export default function Homne() {
  

    return (
        <div>
             <TransactionsWidget />
        </div>
       
    );

}