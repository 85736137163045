import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { ITransaction } from "../../models/ITransaction";
import { faHome, faDollarSign, faUser, faCheck, faFileMedical, faUsers, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

export default function DatatableSimple(props) {

    const $ = require('jquery');
    $.DataTable = require('datatables.net');

    useEffect(() => {
    }, []);

    $('#datatable').DataTable({
        searching: false, paging: false, info: false, 
        data: props.records,
        columns: props.columns,
        "createdRow": function (row, data, dataIndex) {
            if (data["status"] == 'open')
                $(row).addClass('table_row_open');

            if (data["status"] == 'paid')
                $(row).addClass('table_row_paid');

            if (data["status"] == 'fail')
                $(row).addClass('table_row_fail');

            if (data["status"] == 'canceled')
                $(row).addClass('table_row_canceled');

            if (data["status"] == 'canceled')
                $(row).addClass('table_row_canceled');
        }
    }
    );



    return (
        <table id="datatable" className=" row-border" width="100%"></table>
    );
}

