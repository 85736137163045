import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { ITransaction } from "../../models/ITransaction";
import { faHome, faDollarSign, faUser, faCheck, faFileMedical, faUsers, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery';
import MaterialTable from 'material-table'

export default function MyDatatable(props) {

    const $ = require('jquery');
    const [items, SetItenms] = useState(props.records);
    $.DataTable = require('datatables.net');

    useEffect(() => {

        SetItenms(props.records);

        $('#datatable').DataTable({
            data: items,
            columns: props.columns,
            // "order": [[ 1, "asc" ]],
            "createdRow": function (row, data, dataIndex) {
                if (data["status"] == 'open')
                    $(row).addClass('table_row_open');

                if (data["status"] == 'paid')
                    $(row).addClass('table_row_paid');

                if (data["status"] == 'fail')
                    $(row).addClass('table_row_fail');

                if (data["status"] == 'canceled')
                    $(row).addClass('table_row_canceled');

                if (data["status"] == 'canceled')
                    $(row).addClass('table_row_canceled');
            }
        }
        );
    }, []);

    return (
        <div>
            <MaterialTable title="" columns={props.columns} data={items} />
            </div>        // <table id="datatable" className=" row-border" width="100%" ></table>
    );
}

