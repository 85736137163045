import { ACCESS_TOKEN_NAME } from "../general/globals";


export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem(ACCESS_TOKEN_NAME) || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem(ACCESS_TOKEN_NAME);
  sessionStorage.removeItem('user');
}

// set the token and user from the session storage
export const setUserSession = (token, user, firstname, lastname) => {
  sessionStorage.setItem(ACCESS_TOKEN_NAME, token);
  sessionStorage.setItem('firstname', firstname);
  sessionStorage.setItem('lastname', lastname);
  sessionStorage.setItem('user', JSON.stringify(user));
}

// set the token and user from the session storage
export const getLastName = () => {
  return sessionStorage.getItem('lastName');
}