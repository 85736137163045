import React, { } from "react";
import '@fortawesome/free-solid-svg-icons'
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { MoreVert } from "@material-ui/icons";

const DatatbleMenu = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDetailsHandler = (event) => {
        props.handlerDetails(props.id);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <div>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} >
                    <MoreVert color="secondary" />
                </Button>
                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} >
                    <MenuItem onClick={(e) => { handleDetailsHandler(e); handleClose(); }}>Details</MenuItem>
                </Menu>
            </div>
        </div >
    )
}

export default DatatbleMenu;