import React, { useState, useEffect } from "react";
import LoggingService from "../../services/LoggingService";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import { auto } from "@popperjs/core";

export default function LoggingOverview() {
    const history = useHistory();
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (logs.length === 0) {
            LoggingService.get().then(reponse => {
                setLogs(reponse.data);
                console.log(reponse.data);
                setLoading(false);
            }).catch(error => {
                if (error.response === undefined || error.response.status === 401)
                    history.push("/login")
            })
        }
    });

    const columns = [
        { title: 'Date', field: 'date', cellStyle: { width: "15%" }, render: rowData => <span style={{ width: 50, borderRadius: '50%' }}>{rowData.date}</span> },
        { title: 'Level', field: 'level', cellStyle: { width: "10%" }, className: 'dt-body-center' },
        { title: 'Message', field: 'message' }
    ];

    return (
        <div className="form">
            {!loading ?
                <div>
                    <MaterialTable title="Logs" columns={columns} data={logs} options={{ sorting: true, filtering: true }} />
                </div>
                : null}
        </div>
    );
}