const EMAIL="Email:"
const FIRSTNAME="First name:"
const LASTNAME="Last name:"
const PHONE="Phone:"

export default
  {
    EMAIL,
    FIRSTNAME,
    LASTNAME,
    PHONE,
  }