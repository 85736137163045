import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ICustomer } from '../../models/ICustomer';
import CustomerService from '../../services/CustomerService';
import MyDatatable from '../general/myDatatable';
import CustomerDetailsPanel from "./customerDetailsPanel";
import DatatbleMenu from '../general/datatableMenu';
import DatatableLabel from '../general/datatableLabel';
import { Drawer, Grid, SwipeableDrawer } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

export default function CustomersOverview() {
    const [data, setData] = useState<ICustomer[]>();
    const [item, setItem] = useState<ICustomer>();
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [id, setId] = useState<number>(0);
    const history = useHistory();
    const initialCustomer: ICustomer = ({ firstname: "", lastname: "", email: "", phone: "", addresses: [] });

    const columns = [
        {
            title: "Name", field: "firstname",
            render: rowData => <span>{rowData.firstname + " " + rowData.lastname}</span>
        },
        { title: "Email", field: "email", render: rowData => <span>{rowData.email}</span> },
        { title: "Phone", field: "phone", render: rowData => <span>{rowData.phone}</span> },
        {
            title: "Actions", width: '15%', className: 'dt-body-center',
            render: rowData => <DatatbleMenu handlerDetails={retrieveDetails} id={rowData.id} />
        }
    ]

    useEffect(() => {
        CustomerService.getAll().then(reponse => {
            setData(reponse.data);
            setLoading(false)
        }).catch(error => {
            if (error.response == undefined || error.response.status === 401)
                history.push("/login")
        });
    }, []);

    const retrieveDetails = (id) => {
        setId(id);
        setShowDetails(true);
    }

    const closeDetails = () => {
        setShowDetails(false);
    }

    const toggleDrawer = (anchor: boolean, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
    }


    return (
        <div >
            { !loading ?
                <div>
                    <MyDatatable id="table" records={data} title="Customers" columns={columns} />
                    <div id="datailsPanel">
                        <SwipeableDrawer
                            anchor="right"
                            open={showDetails}
                            onClose={toggleDrawer(showDetails, false)}
                            onOpen={toggleDrawer(showDetails, false)}>
                            <div className="detailPanel">
                                <CustomerDetailsPanel id={id} close={closeDetails} item={item} />
                            </div>
                        </SwipeableDrawer>
                    </div>
                </div>
                : null}
        </div>
    );
}