import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@fortawesome/free-solid-svg-icons'
import { faHome,faBars, faDollarSign, faUser, faCheck, faFileMedical, faUsers, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

export default function SubMenuItem(props) {
   
    return (
        <li><a className="nav-link" href={props.link} ><i className={props.icon}></i>
        <span className="ml-2">{props.display} </span>
    </a></li>
    );
}

