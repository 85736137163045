import ApiConfig from '../../src/configuration/apiConfig';
import { getToken } from "../utils/Common";
const axios = require('axios');

const getAll = () => {
  var config = {
    method: 'get',
    url: ApiConfig.API_CONTROLLER_RENTING + ApiConfig.API_CONTROLLER_METHODS_GET_ALL,
    headers: {
      'Authorization':'Bearer ' + getToken(),
      'Content-Type': 'text/plain'
    },
  };
  return axios(config);
}

export default
  {
    getAll
  }