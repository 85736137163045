
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect, useRef } from "react";
import { removeUserSession } from "../../utils/Common";
import { IUserSessionsData } from './../../models/IUserSessionsData';
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { createPopper } from '@popperjs/core';

export default function Topbar() {

    const [user, setUser] = useState(null);
    const [lastName, setLastname] = useState(sessionStorage.getItem('lastname'));
    const [firstname, setFirstname] = useState(sessionStorage.getItem('firstname'));
    const history = useHistory();
    
    useEffect(() => {
    }, []);


 const handleLogout = () => {
    removeUserSession();
    history.push('/login');
  }

    return (
        <div>
            <nav className="navbar navbar-light bg-light p-3">
                <div className="d-flex col-12 col-md-3 col-lg-2 mb-2 mb-lg-0 flex-wrap flex-md-nowrap justify-content-between">
                    <a className="navbar-brand" href="#">
                        CDA Admin
      </a>
                    <button className="navbar-toggler d-md-none collapsed mb-3" type="button" data-toggle="collapse" data-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="col-12 col-md-5 col-lg-8 d-flex align-items-center justify-content-md-end mt-3 mt-md-0">
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                            Hello,{firstname}</button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {/* <li><a className="dropdown-item" href="#">Settings</a></li>
                            <li><a className="dropdown-item" href="#">Messages</a></li> */}
                            <li><a className="dropdown-item" href="#" onClick={()=>handleLogout()}>Sign out</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>


    )
}