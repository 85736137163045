const $ = require('jquery');
$.DataTable = require('datatables.net');

export const columnsTransaction = [
    {
        title: "Customer",
        width: '150px',
        data: "customer.firstname+customer.lastname",
        render: function (data, type, row) {
            return "<a href='/customers/" + row.customer.id +
                "'>" + row.customer.firstname + " " + row.customer.lastname + "</a>";
        }
    },
    { title: "Amount", width: '100px', data: "amount", className: 'dt-body-right', render: $.fn.dataTable.render.number('.', ',', 2, '€ ') },
    { title: "Method", width: '100px', className: 'dt-body-center', data: "method" },
    { title: "Date", data: "createdAt", width: '200px', className: 'dt-body-center' },
    { title: "Status", data: "status", width: '100px', className: 'dt-body-center', },
    { title: "TransactionId", data: "transactionId" },
    {
        title: "Actions",
        width: '70px',
        className: 'dt-body-center',
        render: function (data, type, row, meta) {
            return "<button id='" + row.id + "' class='btn btn-primary btn-sm'>Details</button >";
        }
    }]

export const columnsRenting = [
    {
        title: "Name", data: "lastname", width: '150px',
        render: function (data, type, row, meta) {
            return "<a href='/customers/" + row.customer.id +
                "'>" + row.customer.lastname + " " + row.customer.firstname + "</a>"
        }
    },
    { title: "M²", className: 'dt-body-right', data: "surface", render: $.fn.dataTable.render.number('.', ',', 2), width: '50px' },
    {
        title: "> 1945",
        width: '70px',
        className: 'dt-body-center',
        data: "before1945",
        render: function (data, type, row, meta) {
            if (data == true)
                return "<i class='fas fa-check-circle icon_green' ></i>"
            if (data == false)
                return "<span hidden>data</span> <i class='fas fa-times-circle icon_red' ></i>"
        }
    }, {
        title: "Elevator", width: '70px', className: 'dt-body-center', data: "elevatorPresent",
        render: function (data, type, row, meta) {
            if (data == true)
                return "<i class='fas fa-check-circle icon_green' ></i>"
            if (data == false)
                return "<span hidden>data</span> <i class='fas fa-times-circle icon_red' ></i>"
        }
    },
    {
        title: "Garage",
        width: '70px',
        className: 'dt-body-center',
        data: "garagePresent",
        render: function (data, type, row, meta) {
            if (data == true)
                return "<i class='fas fa-check-circle icon_green' ></i>"
            if (data == false)
                return "<span hidden>data</span> <i class='fas fa-times-circle icon_red' ></i>"
        }
    }, {
        title: "Addic",
        width: '70px',
        className: 'dt-body-center',
        data: "unfinishedAttic",
        render: function (data, type, row, meta) {
            if (data == true)
                return "<span hidden>data</span> <i class='fas fa-check-circle icon_green' ></i>"
            if (data == false)
                return "<i class='fas fa-times-circle icon_red' ></i>"
        }
    },
    {
        title: "Actions",
        width: '70px',
        className: 'dt-body-center',
        render: function (data, type, row, meta) {
            return "<button type='button' class='btn btn-light cadetblue'>Details</button>"
        }
    }
]

export const columnsAddress = [
    { title: "Street", data: "street" },
    { title: "Number", data: "number" },
    { title: "Zip", data: "zip" },
    { title: "City", data: "city" }]


    export const columnsLogs = [
        { title: "Date",  width: '70px', data: "date" },
        { title: "Level",   width: '70px',data: "level" },
        { title: "Message", data: "message" }]


//https://xpage.me/2016/09/14/adding-icon-columns-to-datatables-with-fontawesome/