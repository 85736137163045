import ApiConfig from '../configuration/apiConfig';
import { getToken } from "../utils/Common";
const axios = require('axios');

const get = () => {
  var config = {
    method: 'get',
    url: ApiConfig.API_CONTROLLER_LOGGING + ApiConfig.API_CONTROLLER_METHODS_GET,
    headers: {
      'Authorization': 'Bearer ' + getToken(),
    },
  };
  return axios(config);
}

export default
  {
    get
   }