import React, { useState, useEffect, useRef } from "react";
import RentingService from "../../services/RentingService";
import { columnsRenting } from "./../../general/columnsConfiguration";
import { IRentingInsurance } from './../../models/IRentingInsurance';
import { useHistory } from "react-router-dom";
import MyDatatable from '../general/myDatatable';

export default function RentingOverview() {

    const [data, setData] = useState<IRentingInsurance[]>();
    const history = useHistory();

    useEffect(() => {
        RentingService.getAll().then(reponse => { setData(reponse.data); }).catch(error => {
            if (error.response.status === 401)
                history.push("/login")
        })
    }, []);

    return (
        <div className="form">
            <MyDatatable records={data} columns={columnsRenting} />
        </div>
    );
}

