import React from 'react';
import Topbar from './topbar';
import SideBar from './sidebar';
import Footer from './footer';

const Layout = ({ children }) => {
    return (
        <div>
            <Topbar />
            <SideBar />
            <main className="col-md-9 ml-sm-auto col-lg-10">
                {children}
            </main>
            
        </div>
    )
}

export default Layout;