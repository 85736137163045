import React, { useState, useEffect } from "react";
import { ICustomer } from '../../models/ICustomer';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import LABELS from './../../general/Labels';
import CustomerService from '../../services/CustomerService'
import { CircularProgress, Grid, Table } from "@material-ui/core";

export default function CustomersDetail() {
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState<boolean>(true);
    const { id }: any = useParams();
    const { lang }: any = useParams()
    const initialCustomer: ICustomer = ({ firstname: "", lastname: "", email: "", phone: "", addresses: [] });
    const [customer, setCustomer] = useState(initialCustomer);
    useEffect(() => {
        CustomerService.get(id).then(reponse => { setCustomer(reponse.data); setLoaded(true) });
    }, []);

    return (
        <div className="form">
            {loaded ? <div>
                <div>
                    <Grid>
                        <Table>
                              <thead>
                            <tr>
                                <th colSpan={4}>Personal Information</th>
                            </tr>
                        </thead>
                            <tr>
                                <td>{LABELS.FIRSTNAME}</td>
                                <td>{customer.firstname}</td>
                                <td>{LABELS.LASTNAME}</td>
                                <td>{customer.firstname}</td>
                            </tr>
                            <tr>
                                <td>{LABELS.EMAIL}</td>
                                <td>{customer.email}</td>
                                <td>{LABELS.PHONE}</td>
                                <td>{customer.phone}</td>
                            </tr>
                        </Table>
                    </Grid>
                    <Grid style={{ marginBottom: 20 }}>
                        <Table>
                            <thead>
                                <tr>
                                    <th >#</th>
                                    <th colSpan={4}>Addresses</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customer.addresses.map((address, index) =>
                                    (
                                        <tr><td style={{ width: 30, textAlign: 'center' }}>{index}</td><td>{address.street} {address.number},{address.zip}, {address.city}</td></tr>
                                    ))
                                }</tbody>
                        </Table>
                    </Grid>
                </div>
                <div id="insurance">
                </div>
                <div id="transactions">
                </div>
            </div> :               <CircularProgress />
            }
        </div>
    );
}

