import React, { Component } from 'react';
import { BrowserRouter as Router, } from 'react-router-dom';
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';


export default function CDABreadcrumbs() {

  const routes = {
    '/': 'Home',
    '/customers': 'customers',
    '/customers/:id': 'customer details',
    '/transactions': 'transactions'
  };

  return (
   <div>
 <Router>
          <Breadcrumbs mappedRoutes={routes} />
      </Router>
   </div>

);

}

