import ApiConfig from '../../src/configuration/apiConfig';
import { getToken } from "../utils/Common";
const axios = require('axios');

const get = (id) => {
  var config = {
    method: 'get',
    url: ApiConfig.API_CONTROLLER_TRANSACTIONS + ApiConfig.API_CONTROLLER_METHODS_GET,
    headers: {
      'Authorization': 'Bearer ' + getToken(),
      'Content-Type': 'text/plain'
    },
  };
  return axios(config);
}


const getAll = () => {
  var config = {
    method: 'get',
    url: ApiConfig.API_CONTROLLER_TRANSACTIONS + ApiConfig.API_CONTROLLER_METHODS_GET_ALL,
    headers: {
      'Authorization': 'Bearer ' + getToken(),
      'Content-Type': 'text/plain'
    },
  };
  return axios(config);
}

const getLatest = () => {
  var config = {
    method: 'get',
    url: ApiConfig.API_CONTROLLER_TRANSACTIONS + ApiConfig.API_CONTROLLER_METHODS_GET_LATESTS,
    headers: {
      'Authorization': 'Bearer ' + getToken(),
      'Content-Type': 'text/plain'
    },
  };
  return axios(config);
}

export default
  {
    getAll,
    getLatest
  }