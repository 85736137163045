import React from "react";
import { BrowserRouter as Router, Redirect, Switch, Route } from "react-router-dom";
import Homne from './components/home';
import Layout from './components/layout/layout';
import Login from './components/login';
import { userLoggedIn } from './general/globalFunctions';
import '../src/css/login.css';
import '../src/css/dashboard.css';
import TransactionsOverview from './components/transactions/transactions_overview';
import PageNotFound from './components/layout/PageNotFound';
import RentingOverview from './components/renting/renting_overview';
import CustomersOverview from './components/customers/customersOverview';
import CustomersDetail from './components/customers/customer_detail';
import LoggingOverview from './components/logging/logging_overview';

export default function App() {
 
  return (
    <div>
    <Route exact path="/login"><Login /></Route>
    <div >
      <Switch>
      <Route exact path="/" ><Login /></Route>
      <PrivateRoute exact path="/home" ><Layout><Homne /></Layout></PrivateRoute>
      <PrivateRoute exact path="/customers/:id" ><Layout><CustomersDetail /></Layout></PrivateRoute>
      <PrivateRoute exact path="/customers" ><Layout><CustomersOverview /></Layout></PrivateRoute>
      <PrivateRoute path="/transactions" ><Layout><TransactionsOverview /></Layout></PrivateRoute>
      <PrivateRoute path="/renting" ><Layout><RentingOverview /></Layout></PrivateRoute>
      <PrivateRoute path="/logging" ><Layout><LoggingOverview /></Layout></PrivateRoute>
      {/* <Route component={PageNotFound} /> */}
     </Switch>
    </div>
</div>
  );
}
 
function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        userLoggedIn() ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}



//https://www.cluemediator.com/login-app-create-login-form-in-reactjs-using-secure-rest-api
//https://themesberg.com/blog/bootstrap/simple-bootstrap-5-dashboard-tutorial