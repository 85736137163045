import React, { useState, useEffect, useRef } from "react";
import { ITransaction } from "../../models/ITransaction";
import TransactionService from "../../services/TransactionService";
import { columnsTransaction } from "./../../general/columnsConfiguration";
import { useHistory } from "react-router-dom";
import MyDatatable from '../general/myDatatable';
import CustomerDetailsPanel from './../customers/customerDetailsPanel';

export default function TransactionsOverview() {
    const history = useHistory();
    const [transactions, setTransactions] = useState<ITransaction[]>();
    const [hideDetails, setHideDetails] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [id, setId] = useState<number>(0);

    useEffect(() => {
        TransactionService.getAll().then(reponse => {
            setTransactions(reponse.data);
            setLoading(false);
        }).catch(error => {
            if (error.response.status === 401)
                history.push("/login")
        })
    }, []);

    const closeDetails = () => {
        setHideDetails(true);
    }

    const getDetails = (e) => {
        setId(e);
        setHideDetails(false);
    }



    return (
        <div className="form">
            { !loading ?
                <div>
                    <MyDatatable records={transactions} columns={columnsTransaction} detailsHandlder={getDetails}/>
                    <div hidden={hideDetails}>
                         <CustomerDetailsPanel item_id={id} close={closeDetails} /> 
                    </div>
                </div>
                : null}
        </div>
    );
}