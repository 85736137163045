 const API_BASE_URL= "https://api.cda.be:21996/";
//API_BASE_URL:"http://invoices.sbsoftware.be/api/";
//const API_BASE_URL = "https://localhost:44369/";
const API_CONTROLLER_SECURITY = API_BASE_URL + "security";
const API_CONTROLLER_TRANSACTIONS = API_BASE_URL + "transactions";
const API_CONTROLLER_CUSTOMERS = API_BASE_URL + "customer";
const API_CONTROLLER_LOGGING = API_BASE_URL + "log";
const API_CONTROLLER_RENTING = API_BASE_URL + "RentingInsurance";

const API_CONTROLLER_METHODS_CREATE = "/create";
const API_CONTROLLER_METHODS_ADD = "/add";
const API_CONTROLLER_METHODS_GET = "/get";
const API_CONTROLLER_METHODS_GET_ALL = "/getall";
const API_CONTROLLER_METHODS_VALIDATE = "/validate";
const API_CONTROLLER_METHODS_GET_LATESTS = "/getLatest";

export default
  {
    API_CONTROLLER_SECURITY,
    API_CONTROLLER_TRANSACTIONS,
    API_CONTROLLER_CUSTOMERS,
    API_CONTROLLER_RENTING,
    API_CONTROLLER_METHODS_CREATE,
    API_CONTROLLER_METHODS_ADD,
    API_CONTROLLER_METHODS_GET,
    API_CONTROLLER_METHODS_GET_ALL,
    API_CONTROLLER_METHODS_VALIDATE,
    API_CONTROLLER_LOGGING,
    API_CONTROLLER_METHODS_GET_LATESTS
  }