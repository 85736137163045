
import { getToken } from "../utils/Common";
import ApiConfig from '../../src/configuration/apiConfig';
const axios = require('axios');

const get = (id) => {
  var config = {
    method: 'post',
    url: ApiConfig.API_CONTROLLER_CUSTOMERS + ApiConfig.API_CONTROLLER_METHODS_GET +"/"+ id,
    headers: {
      'Authorization':'Bearer ' + getToken(),
      'Content-Type': 'text/plain'
    },
    data: id
  };
  return axios(config);
}

const getAll = () => {
  var config = {
    method: 'get',
    url: ApiConfig.API_CONTROLLER_CUSTOMERS + ApiConfig.API_CONTROLLER_METHODS_GET_ALL,
    headers: {
      'Authorization':'Bearer ' + getToken(),
      'Content-Type': 'text/plain'
    },
  };
  return axios(config);

}

export default {
  getAll,
  get,
};