import React, { useEffect, useState } from "react";
import '@fortawesome/free-solid-svg-icons'
import CustomerService from './../../services/CustomerService';
import { ICustomer } from './../../models/ICustomer';
import { Avatar, Grid, makeStyles } from '@material-ui/core';
import '../../css/detailPanel.css'
import CancelIcon from '@material-ui/icons/Cancel';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { Height } from "@material-ui/icons";
const CustomerDetailsPanel = (props) => {

    const initialCustomer: ICustomer = ({ firstname: "", lastname: "", email: "", phone: "", addresses: [] });
    const [loading, setLoading] = useState<boolean>(true);
    const [item, setItem] = useState<ICustomer>(initialCustomer);

    useEffect(() => {
        CustomerService.get(props.id).then(reponse => {
            setItem(reponse.data);
            setLoading(false);
        }).catch(error => {
            if (error.response.status === 401) { }
        })
    }, [props.id]);

    return (<div>
       
        {!loading ?
            <div>
                <Grid container justify="center">
                    <Avatar className="close_avatar">
                        <CancelIcon style={{ width: 50, height: 50, cursor: "pointer" }} onClick={props.close} />
                    </Avatar>
                </Grid>
                 <hr></hr>
                <div className="detailPanel_content">
                    <Grid container spacing={3}>
                        <Grid item xs={3}>Firstname:</Grid>
                        <Grid item xs>{item.firstname}</Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ textAlign: "left" }}>
                        <Grid item xs={3}>Lastname:</Grid>
                        <Grid item xs>{item.lastname}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>Email:</Grid>
                        <Grid item xs>{item.email}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>Phone:</Grid>
                        <Grid item xs>{item.phone}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item >Addresses</Grid>
                    </Grid>
                    <ul>
                        {item.addresses.map(d => (<li key={d.id}>{d.number} {d.street}, {d.zip} {d.city}</li>))}
                    </ul>
                </div>
                <hr></hr>
            </div>
            : null}
    </div>
    )
}

export default CustomerDetailsPanel

