import React, { useState, useEffect, useRef } from "react";
import { Alert, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BrowserRouter as Redirect } from "react-router-dom";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { setUserSession } from "../utils/Common";
import { ICredentials } from "../models/ICredentials";
import '../css/login.css';
import * as ReactBootstrap from 'react-bootstrap';
import axios, { AxiosRequestConfig } from "axios";
import apiConfig from "../configuration/apiConfig";

export default function Login() {

    const [username, setUserName] = useState<string>();
    const [password, setPassword] = useState<string>();
    const { register, handleSubmit, errors } = useForm({});
    const history = useHistory();
    const [error, setError] = useState<string>();
    const [errorOccured, setErrorOccured] = useState<boolean>();
    const [loading, setLoading] = useState(false);

    useEffect(() => { }, []);

    const handleLogin = async () => {
        setError('');
        setErrorOccured(false);
        setLoading(true);
        const credentials = {} as ICredentials;
        credentials.username = username;
        credentials.password = password;

        var data = JSON.stringify(credentials);

        const configAxios: AxiosRequestConfig = {
            method: 'post',
            url:  apiConfig.API_CONTROLLER_SECURITY + apiConfig.API_CONTROLLER_METHODS_VALIDATE,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios(configAxios)
            .then(function (response) {
                setLoading(false);
                setUserSession(response.data.bearerToken, 
                    response.data.userName,
                    response.data.firstname,
                    response.data.lastname);
                history.push('/home');
            })
            .catch(error=> {
                setLoading(false);
                console.log(error); 
                 setErrorOccured(true);
                     setError("Invalid user or password");
                    setError("Something went wrong. Please try again later.");
            });
    }

    return (
        <div className="container">
            <div className="row justify-content-md-center">
                <div className="col col-sm-8 col-md-5 login_screen_border login_screen">
                    <form>
                        <h3>Sign In</h3>
                        <div className="form-group">
                            <label>Email address</label>
                            <input type="email" 
                            className="form-control"
                             placeholder="Enter email" name="username" 
                             onChange={(e) => setUserName(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" 
                            className="form-control" placeholder="Enter password" 
                            name="password" onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                            </div>
                        </div>
                        <div className="form-group" hidden>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                            </div>
                        </div>
                        <div className="form-group" hidden={!errorOccured}>
                                <ReactBootstrap.Alert variant='danger' >{error}</ReactBootstrap.Alert>
                        </div>
                        <button disabled={loading} type="submit" className="btn btn-primary btn-block" onClick={handleLogin}>Submit</button>
                        <p className="forgot-password text-right">
                            {/* Forgot <a href="#">password?</a> */}
                        </p>
                    </form>
                </div>
            </div>
        </div>
    )
}


