import React, { useState, useEffect } from "react";
import { ITransaction } from "../../models/ITransaction";
import TransactionService from "../../services/TransactionService";
import DatatableSimple from './../general/datatableSimple';
import { columnsTransaction } from "./../../general/columnsConfiguration";
import { useHistory } from "react-router-dom";

export default function TransactionsWidget() {
    const history = useHistory();
    const [transactions, setTransactions] = useState<ITransaction[]>();

    useEffect(() => {
        TransactionService.getLatest().then(reponse => {setTransactions(reponse); }).catch(error => {
            if (error.response.status === 401)
                history.push("/login")
        });
    }, []);

    return (
        <div className="transactions_widget">
            <span>Latest transactions</span>
            <hr></hr>
            <DatatableSimple records={transactions} columns={columnsTransaction}  />
        </div>
    );
}