import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faBars, faDollarSign, faUser, faFingerprint, faFileMedical, faUsers, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import CDABreadcrumbs from './CDABreadcrumbs';
import SubMenuItem from './submenuItem';

export default function SideBar() {


    const [hideRenting, setHideRenting] = useState(true);


    const showSubMenu = (e: any, menu) => {
        setHideRenting(true);

        e.preventDefault();

        if (menu === "RENTING" && hideRenting===true)
            setHideRenting(false);
            if (menu === "RENTING" && hideRenting===false)
            setHideRenting(true);
    }

    return (

        <div>
            <div className="container-fluid">
                <div className="row">
                    <nav id="sidebar" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                        <div className="position-sticky">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/home">
                                        <FontAwesomeIcon icon={faHome} size="1x" />
                                        <span className="ml-2">Dashboard</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/logging">
                                        <FontAwesomeIcon icon={faFingerprint} size="1x" />
                                        <span className="ml-2">Logs</span>
                                    </a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="/transactions">
                                        <FontAwesomeIcon icon={faDollarSign} size="1x" />
                                        <span className="ml-2">Transactions</span>
                                    </a>
                                </li> */}
                                <li className="nav-item">
                                    <a className="nav-link" href="/customers">
                                        <FontAwesomeIcon icon={faUser} size="1x" />
                                        <span className="ml-2">Customers</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/renting" onClick={(e) => { showSubMenu(e, "RENTING") }}>
                                        <FontAwesomeIcon icon={faFileMedical} size="1x" />
                                        <span className="ml-2">Renting </span>
                                    </a>
                                    <ul hidden={hideRenting} style={{ listStyle: "none" }}>
                                        <SubMenuItem display="Overview" link="/renting" icon="fas fa-bars" />
                                        <SubMenuItem display="Simulation" link="/renting" icon="fas fa-square-root-alt" />
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    <main className="col-md-9 ml-sm-auto col-lg-10" style={{ padding: 15 }}>
                        <CDABreadcrumbs ></CDABreadcrumbs>
                    </main>
                </div>
            </div >
        </div >


    )
}